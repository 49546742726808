import './App.css';
import logo from "./images/siteLogo.png"
import footerbg from "./images/footerBG.png"

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Hero></Hero>
      <Seperator></Seperator>
      <Tokenomics></Tokenomics>
      <LineSeperator></LineSeperator>
      <Treasury></Treasury>
      <FooterSeperator></FooterSeperator>
      <Footer></Footer>
    </div>
  );
}

function Header(){
  return(
    <div id='Header'>
      <div id="ColorStrip">
        <a href='https://x.com/sonicnode_SNC' style={{fontWeight:"bolder"}}>// Follow on X // Follow on X // Follow on X //</a>
      </div>
      <div id="Menu">
        <div id='MenuLeft'>
          <img src={logo} alt="siteLogo" />
        </div>
        <div id='MenuRight'>
          <a href="#Tokenomics">Tokenomics</a>
          <a href="#Treasury">How it Works</a>
          <button>Buy SNC</button>
        </div>
      </div>
    </div>
  )
}

function Hero(){
  return(
    <div id='Hero'>
      <div id='HeroTitle'>
        <h1><span style={{color: "orange", fontStyle:"italic"}}>More</span> than<br/>just a token</h1>
      </div>
      <div id='HeroSubtitle'>
        <h3>Sonic Node Capital is a token that offers holders buybacks and burns using the treasury profits.</h3>
      </div>
      <div id='HeroButtons'>
        <button>Buy SNC</button>
        <button style={{background: "none"}} onClick={e=>{e.preventDefault()
          window.open("https://t.me/sonicnodecapital")
        }}>Join Community ></button>
      </div>
    </div>
  )
}

function Tokenomics(){
  return(
  <div id='Tokenomics'>
    <div id='TokenomicsLeft'>
      <h1>Real community token with real movement</h1>
      <h3>Sonic Node Capital is the first Node Capital project on Sonic Mainnet.</h3>
    </div>
    <div id='TokenomicsRight'>
      <div id='TokenomicsSection'>
        <h2>Total Supply</h2>
        <h1>1,000,000</h1>
      </div>
      <div id='TokenomicsSection'>
        <h2>Buy and Sell Tax</h2>
        <h1>5%</h1>
      </div>
      <div id='TokenomicsSection'>
        <h2>$S in Treasury</h2>
        <h1>TBA</h1>
      </div>
    </div>
  </div>
  )
}

function Seperator(){
  return(
    <div id='Seperator'></div>
  )
}

function LineSeperator(){
  return(
    <div id='LineSeperator'></div>
  )
}

function Treasury(){
  return(
    <div id='Treasury'>
    <div id='TreasuryLeft'>
      <h1>Enjoy the buybacks and watch <span style={{color:"orange", fontStyle:"italic"}}>SNC</span> grow</h1>
      <h3>With SNC VaaS ( Ventures as a Service ) protocol, enjoy the team generating buybacks and burns of SNC using the treasury.</h3>
      <button style={{width:"fit-content"}}
      onClick={e => {
        e.preventDefault()
        window.open("https://t.me/sonicnodecapital")
      }}
      >Join the Community</button>
      
    </div>
    <div id='TreasuryRight'>
      <div id='TreasurySection'>
        <h2>The Buys and Sells will generate tax volume for</h2>
        <h1>Treasury</h1>
      </div>
      <div id='TreasurySection'>
        <h2>The SNC team will venture using the treasury for</h2>
        <h1>Profits</h1>
      </div>
      <div id='TreasurySection'>
        <h2>The returns through the ventures will be used for</h2>
        <h1>Buybacks and Burns</h1>
      </div>
    </div>
  </div>
  )
}

function FooterSeperator(){
  return(
    <div id='FooterSeperator'>
      <img src={footerbg} alt="footerBG" />
    </div>
  )
}

function Footer(){
  return(
    <div id='Footer'>
      <div id='FooterLeft'>
        <img src={logo} alt="siteLogo" />
        <span>© Sonic Node Capital - 2024</span>
      </div>
      <div id='FooterRight'>
        <div id='FooterSection'>
          <span>Socials</span>
          <a href="https://x.com/sonicnode_SNC">Twitter / X</a>
          <a href="https://t.me/sonicnodecapital">Telegram</a>
        </div>
        <div id='FooterSection'>
          <span>Financials</span>
          <a href="#">Dexscreener</a>
          <a href="#">Dextools</a>
        </div>
      </div>
    </div>
  )
}
export default App;
